import './App.css';
import {request, gql} from 'graphql-request'
import {useState} from "react";

function exportToCsv(filename, rows) {
    var processRow = function (row) {
        var finalVal = '';
        for (var j = 0; j < row.length; j++) {
            var innerValue = row[j] === null ? '' : row[j].toString();
            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString();
            }
            ;
            var result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0)
                result = '"' + result + '"';
            if (j > 0)
                finalVal += ',';
            finalVal += result;
        }
        return finalVal + '\n';
    };

    var csvFile = '';
    for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i]);
    }

    var blob = new Blob([csvFile], {type: 'text/csv;charset=utf-8;'});
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

async function fetchNFTs(setMessage) {
    const fetchPage = async (collectionID, offset, limit) => {
        const result = await request({
            url: 'https://api-v2.ongaia.com/graphql/',
            document: gql`
      query SearchMarketplaceNFTs($input: SearchMarketplaceNFTsInputV2!) {
      searchMarketplaceNFTsV2(input: $input) {
        marketplaceNFTs {
          nftID
          name
          owner
          collectionSlug
        }
      }
    }
    `,
            variables: {
                input: {
                    collectionID,
                    isBurned: false,
                    orderBy: 'serial_number_asc',
                    offset,
                    limit,
                },
            },
        })

        return result.searchMarketplaceNFTsV2.marketplaceNFTs
    }

    const fetchAll = async (collectionID) => {
        const nfts = []

        let offset = 0
        while (true) {
            setMessage(`Fetching ${collectionID} from offset ${offset}`)
            const page = await fetchPage(collectionID, offset, 100)

            nfts.push(...page)

            if (page < 100) {
                break
            }

            offset += page.length
        }

        return nfts
    }

    const ballerz = await fetchAll('ballerz');
    const sneakerz = await fetchAll('sneakerz');

    setMessage('Generating CSV');

    const rows = [
        ["Collection", "NFT ID", "NFT Name", "Owner"],
        ...([...ballerz, ...sneakerz].map(n => ([
                n.collectionSlug ?? '',
                n.nftID ?? '',
                n.name ?? '',
                n.owner ?? '',
            ]))
        )]

    exportToCsv('nfts.csv', rows);
}

function App() {
    const [message, setMessage] = useState('')

    return (
        <div className="App">
            <div style={{marginTop: 20, marginBottom: 20}}>
                <button onClick={() => fetchNFTs(setMessage)}>Generate CSV</button>
            </div>
            <div>
                {message}
            </div>
        </div>
    );
}

export default App;
